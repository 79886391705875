import React from "react"
import {Link} from "gatsby"
import kebabCase from 'lodash/kebabCase';

import Layout from "../components/layout"
import SEO from "../components/seo";
import Social from '../components/social';

export default function StateTaxForm({pageContext}) {
  const { form,forms, stateName, formName, file_name } = pageContext;
  const {form_name, form_description, url} = form;

  const currentDate = new Date().getFullYear();
  const stateIndex = `/tax-forms/${kebabCase(stateName)}`;

  return (
    <Layout>
      <SEO title={form_name + " Tax Form Download for " + stateName} description={"Download " + file_name + ".pdf now. " + form_description}/>
      <div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item"><Link to="/tax-forms">Tax Forms</Link></div>
                  <div className="breadcrumb-item"><Link to={stateIndex} >{stateName} Tax Forms</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">{form_name}</div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <p className="hero-meta">Last updated: {currentDate}</p>
              <h1 className="hero-heading">{form_name} </h1>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-9">
              <Social />
              <hr/>
              <h2>{form_name}</h2>
              <p>{form_description}</p>


              <div>
                Form Link: <Link to={url}>{form_name}</Link>
              </div>
              <br/>


            </div>
            <div className="col-sm-12 col-md-3">
              <h2>{stateName} Tax Forms</h2>
              <ul>
                {forms.map(form => {
                  const formName = form.form_name.replace(/\s/g, '-').toLowerCase();

                  let slug = "/tax-forms/" + kebabCase(stateName) + "/" + formName;

                  return(
                    <li>
                      <Link to={slug}>{form.form_name}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            {/* End main column */}
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
    </Layout>
  );
}

